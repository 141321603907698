<template>
  <div justify="end">
    <v-dialog v-model="isVisibleDialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="mx-5" v-bind="attrs" v-on="on">
          {{ $t("import_csv") }}
        </v-btn>
      </template>
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("order_csv") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        dense
                        hide-details="auto"
                        outlined
                        @change="onFileChanged($event)"
                        type="file"
                        label="csv"
                        prepend-icon=""
                        append-icon="mdi-file"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row v-if="upload_error">
                    <v-col cols="12">
                      <v-alert type="error" dense outlined class="pa-2 mb-0">
                        {{ upload_error }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="uploadCSV(products)"
              :disabled="!is_valid"
              :loading="is_uploading"
            >
              {{ $t("upload") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  data() {
    return {
      upload_error: "",
      products: {},
      is_valid: true,
      is_uploading: false,
      isVisibleDialog: false,
    };
  },
  methods: {
    closeForm() {
      this.upload_error = "";
      this.$refs.form.reset();
      this.isVisibleDialog = false;
      (this.is_uploading = false), (this.products = {});
    },

    async uploadCSV(products) {
      if (this.$refs.form.validate()) {
        try {
          this.is_uploading = true;
          this.upload_error = "";
          await this.$store.dispatch("orders/uploadCSV", products);
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          this.is_uploading = false;
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.upload_error = error.response?.data?.message;
          this.is_uploading = false;
        }
        this.is_uploading = false;
      }
    },

    onFileChanged(e) {
      this.products.csv = e;
    },
  },
};
</script>
